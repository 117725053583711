import React, { useState } from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import { CHOOSUS } from "../constants";
import SliderHome from "../components/SliderHome";
import FooterWeb from "../layouts/FooterWeb";

function WhyChooseUsScreen() {
  const [selectChose, setSelectChose] = useState("");
  return (
    <div className="bg-white text-black min-h-screen">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Why Choose Us for Hijama Training?
            </div>
          </div>
        </div>
      </div>
      <div className="bg-warning ">
        <div className="container mx-auto text-center py-10 font-bold text-xl text-white">
          <div>
            Choose Professionalism | Choose Expertise | Choose us for Your
            Hijama Training
          </div>
        </div>
      </div>
      <div className="container mx-auto my-10">
        <div className="font-bold text-xl my-3">
          Welcome to Our Professional Hijama Online Academy Services
        </div>
        <div>
          At HOA, we take pride in offering high-quality and professional hijama
          training. What sets us apart is our commitment to excellence and
          adherence to the highest standards of practice. Here’s why you should
          choose us for your hijama training qualification:
        </div>
        <div className="my-5">
          {CHOOSUS?.map((item, index) => (
            <div
              onClick={() => {
                if (selectChose === item.index) {
                  setSelectChose("");
                } else {
                  setSelectChose(item.index);
                }
              }}
              className=" cursor-pointer"
            >
              <div className="border border-[#d5d8dc] px-4 py-2 font-bold text-xl">
                <div className="flex flex-row items-center">
                  {selectChose === item.index ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  )}

                  <div className="mx-2">{item.index + ". " + item.title}</div>
                </div>
              </div>
              <div
                className={`${
                  selectChose === item.index
                    ? "border border-[#d5d8dc] text-sm px-4 py-2 "
                    : "hidden"
                }  `}
              >
                {item.description}
              </div>
            </div>
          ))}
        </div>

        {/*  */}
        <div class="grid  md:grid-cols-2 grid-cols-1 mt-6 gap-2">
          {CHOOSUS?.map((item, index) => (
            <div className="flex flex-row ">
              <div className="font-bold">{item.index}. </div>
              <div className="flex-1 ">
                <span className="font-bold mx-2">{item.title}</span>
                {item.description}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* slider */}
      <SliderHome />

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default WhyChooseUsScreen;
