import React from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";

function ContactScreen() {
  return (
    <div className="bg-white text-black min-h-screen">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Contact
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto py-6 my-10">
        <div className="text-xl font-bold">
          If you have any questions regarding our online courses, career
          services, or just need help navigating our website, our team of
          friendly professionals are here to assist you. We value your feedback
          and look forward to hearing from you.
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto mb-10">
        <div className="flex md:flex-row flex-col">
          <div className="md:w-1/2 w-full px-4">
            <div className="font-bold mb-5 text-2xl">Contact Details</div>
            <div className="mb-5">
              If you would like to get in touch and speak with a member of our
              team, please feel free to use the details below to get in touch.
            </div>
            {/*  */}
            <div className="flex flex-row items-start my-5">
              <div className="bg-danger rounded-full p-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
              </div>
              <div className="mx-2">
                <div className="font-bold ">Email</div>
                <div className=" ">info@hijamaonlineacademy.com</div>
              </div>
            </div>
            {/*  */}

            <div className="flex flex-row items-start my-5">
              <div className="bg-danger rounded-full p-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
              <div className="mx-2">
                <div className="font-bold ">Opening Times</div>
                <div className=" ">Monday – Sunday : 10:00 AM – 6:00 PM</div>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-row items-start my-5">
              <div className="bg-danger rounded-full p-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
              </div>
              <div className="mx-2">
                <div className="font-bold ">Telephone</div>
                <div className=" ">Tel: +44 7765 436 139</div>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-row items-start my-5">
              <div className="bg-danger rounded-full p-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>
              </div>
              <div className="mx-2">
                <div className="font-bold ">Head Office</div>
                <div className=" ">
                  Hijama Online Academy Unit G35, Fairgate House 205 Kings Road,
                  Tyseley, Birmingham, B11 2AA
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 w-full px-4">
            <div className="font-bold mb-5 text-2xl">How Can We Help?</div>
            <div className="mb-5">
              If you would like more information about the online courses
              available on Oplex Careers or you have any other question, please
              feel free to get in touch with us using the enquiry form below.
            </div>
            <div className="my-3">
              <input
                className="border border-danger px-3 py-2 rounded w-full"
                placeholder="Name"
                type="text"
              />
            </div>
            <div className="my-3">
              <input
                className="border border-danger px-3 py-2 rounded w-full"
                placeholder="Email"
                type="text"
              />
            </div>
            <div className="my-3">
              <input
                className="border border-danger px-3 py-2 rounded w-full"
                placeholder="Phone"
                type="text"
              />
            </div>
            <div className="my-3">
              <textarea
                rows={5}
                className="border border-danger px-3 py-2 rounded w-full"
                placeholder="Phone"
              ></textarea>
            </div>
            <div>
              <div className="bg-danger  px-3 py-2 text-white text-center rounded-full">
                Send your enquiry
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* maps */}

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default ContactScreen;
