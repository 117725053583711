import React, { useState } from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";

import { LISTFAQ } from "../constants";
import SliderHome from "../components/SliderHome";

function HomeScreen() {
  const [itemFaq, setItemFaq] = useState([]);
  return (
    <div className="bg-white ">
      <HeaderWeb />
      {/* carousel */}
      <SliderHome />

      {/* POPULAR */}
      <div
        className="bg-center bg-auto "
        style={{
          backgroundImage:
            'url("https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRmlzbGFtaWMtcGF0dGVybi0xNy5wbmcmY2FjaGVNYXJrZXI9MTcwODAwODU3MC0yNTk1MjAmdG9rZW49MDJiZDI3OTk3OWJiODUzZA.q.png")',
          backgroundSize: "85% auto",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="bg-[#FFFAFA] w-full h-full bg-opacity-80">
          <div className="container mx-auto py-5 ">
            <div className="flex md:flex-row flex-col">
              <div className="md:w-2/3 w-full px-2">
                <div className="px-2 py-3 md:w-[80%] w-full mx-auto">
                  <div className="bg-danger px-3 py-3 text-center relative">
                    <div className="text-white text-xl">
                      Certified Hijama Diploma - Online Course
                    </div>
                    <div className="text-white text-xs">
                      Become a Fully Qualified and Registered Hijama
                      Professional
                    </div>
                    <div className="  absolute top-0 right-0 overflow-hidden w-[150px] h-[150px] rotate-90 ">
                      <div
                        className="bg-black text-white w-[200%] text-center text-[10px] uppercase font-bold mr-4"
                        style={{
                          transform:
                            "translateY(100%) translateX(-56%) translateX(38px) rotate(-63deg)",
                        }}
                      >
                        Popular
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="bg-[#ece9e9]">
                    <div className="text-5xl font-bold text-danger text-center py-10">
                      $100 only
                    </div>
                    <div className="flex flex-row items-center  md:w-[73%] w-[90%]  mx-auto  border-b-2 pb-3 mt-5">
                      <svg
                        aria-hidden="true"
                        class="size-4 mx-1 text-danger"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                        ></path>
                      </svg>
                      <div className="mx-1">12 Modules - Completed Online</div>
                    </div>
                    <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                      <svg
                        aria-hidden="true"
                        class="size-4 mx-1 text-danger"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                        ></path>
                      </svg>
                      <div className="mx-1">
                        11 Videos - Including First Aid
                      </div>
                    </div>
                    <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                      <svg
                        aria-hidden="true"
                        class="size-4 mx-1 text-danger"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                        ></path>
                      </svg>
                      <div className="mx-1">
                        10 Written Clinical Case Studies
                      </div>
                    </div>
                    <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                      <svg
                        aria-hidden="true"
                        class="size-4 mx-1 text-danger"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                        ></path>
                      </svg>
                      <div className="mx-1">Theory Exam Online - Open Book</div>
                    </div>
                    <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                      <svg
                        aria-hidden="true"
                        class="size-4 mx-1 text-danger"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                        ></path>
                      </svg>
                      <div className="mx-1">
                        Practical Hijama Training Workshop*
                      </div>
                    </div>
                    <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                      <svg
                        aria-hidden="true"
                        class="size-4 mx-1 text-danger"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                        ></path>
                      </svg>
                      <div className="mx-1">
                        Clinical Exam - At our Hijama Clinic
                      </div>
                    </div>
                    <div className="flex flex-row items-center md:w-[73%] w-[90%]  mx-auto pb-6 mt-5">
                      <div className="mx-1">
                        * Attendance required at our Hijama Clinic
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="bg-danger py-5 px-3 ">
                    <div>
                      <div className=" rounded-full text-white border border-white px-5 py-2 font-bold w-max mx-auto">
                        Buy Now
                      </div>
                      <div className="text-white text-xs text-center my-2">
                        Enroll Today and Gain Immediate Access to Your Course
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3 w-full px-2">
                <div className="px-2 py-3 bg-white rounded">
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Fully Qualified</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">G.R.C.C.T Registered</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">1-2-1 Personal Tutor</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Self Paced Flexible Study</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Fully Insured</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Certified Course</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">24/7 Email Support</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Online Learning</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Experienced</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Accredited Course</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Extra Tuition Available</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">First Aid Trained</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Access 24/7</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Compatibility</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">No Formal Entry Requirements</div>
                  </div>
                  <div className="flex flex-row text-danger hover:text-black transition-colors duration-300 items-center cursor-pointer my-4  ">
                    <svg
                      aria-hidden="true"
                      class="size-4 "
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="fill-current"
                        // fill="#FF0000"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                      ></path>
                    </svg>
                    <div className="mx-2">Diploma Upon Completion</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ENROL NOW */}
      <div
        className="bg-no-repeat"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRkVucm9sLmpwZyZjYWNoZU1hcmtlcj0xNzEzMTE3OTQ0LTI2NzQ2JnRva2VuPTBmNThlYjgyMzE1ZmNmNzE.q.jpg')",
          backgroundPosition: "center right",
          backgroundSize: "cover",
        }}
      >
        <div className="container mx-auto py-20">
          <div className="md:w-1/2 w-full">
            <div className="text-3xl text-danger mb-5 font-bold">ENROL NOW</div>
            <div className="text-xl text-black mb-3">
              For more information or to book an initial assessment:
            </div>
            <div className="text-sm mb-3">
              Fill out our online contact form or contact us today on (+44) 7765
              436 139 and let us help you be the best that you can be.
            </div>
            <div className=" rounded-full flex flex-row px-5 mt-3 py-2 bg-danger text-white w-max">
              <div>Enrol Today</div>
              <svg
                aria-hidden="true"
                class="size-4 mx-1 "
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="bg-danger py-10 mb-3">
        <div className="container mx-auto text-white  text-center font-bold text-xl">
          <div>Potential Benefits of Enrolling in HOA's Training Program</div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto">
        <div className="flex md:flex-row flex-col items-center">
          <div className="md:w-1/3 w-full">
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Revive A Forgotten Sunnah ﷺ</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">New Job, New Career</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Accelerate Your Future</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Empower Your Mind</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Career Transformation</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Learn Anytime, Anywhere</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Understand Hijama Mechanism</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Explore Benefits of Hijama</div>
            </div>
          </div>
          <div className="md:w-1/3 w-full">
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">
                Comprehensive Training in Hijama Process
              </div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Generate A Good Income</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Boost Community Well-being</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Launch a Prosperous Career</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Flexible Working Hours</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Contribute to Sadaqah Jaariya</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Enhance Work-Life Balance</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Experience Autonomy</div>
            </div>
          </div>
          <div className="md:w-1/3 w-full">
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Take Charge of Your Own Business</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Expand Your Business</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Embrace Entrepreneurship</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Unleash Your Potential</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Customize Your Learning Pace</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Achieve Ongoing Growth</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Discover Fresh Opportunities</div>
            </div>
            <div className="flex flex-row items-center my-3">
              <svg
                aria-hidden="true"
                class="size-4 text-danger"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
              </svg>
              <div className="ml-1">Inspire the World Around You</div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div
        className=" bg-cover bg-no-repeat min-h-[400px] mt-5 "
        style={{
          backgroundImage:
            'url("https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRmN1cHBpbmctb24tYmFjay5qcGcmY2FjaGVNYXJrZXI9MTcxMzExODY1OS0xMjgzNTMmdG9rZW49MDcwYmVhOWUxZjUwNDk5MQ.q.jpg")',
        }}
      >
        <div className="h-full w-full bg-[#4c4c4c] bg-opacity-70 min-h-[400px] ">
          <div className="container mx-auto text-center py-6 text-white min-h-[400px] flex flex-col justify-center items-center">
            <div>
              <svg
                aria-hidden="true"
                class=" size-10 text-white"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                ></path>
              </svg>
            </div>
            <div className="font-bold text-2xl my-3">Watch this video</div>
            <div>Hijama Diploma Course Promo Ad</div>
          </div>
        </div>
      </div>
      {/* FAQs */}
      <div className="bg-warning ">
        <div className="container mx-auto text-center py-10 font-bold text-3xl text-white">
          <div>FAQs</div>
        </div>
      </div>
      <div className="container mx-auto mt-4">
        {LISTFAQ?.map((item, index) => (
          <div
            onClick={() => {
              if (itemFaq.includes(item.index)) {
                setItemFaq(itemFaq.filter((i) => i !== item.index));
              } else {
                setItemFaq([...itemFaq, item.index]);
              }
            }}
            className="my-4  cursor-pointer border-b py-2 border-[#d5d8dc]"
          >
            <div className=" text-base font-bold text-black flex flex-row items-center">
              {itemFaq.includes(item.index) ? (
                <svg
                  class="size-4 mx-1"
                  viewBox="0 0 320 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current"
                    d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"
                  ></path>
                </svg>
              ) : (
                <svg
                  class="size-4 mx-1"
                  viewBox="0 0 192 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current"
                    d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
                  ></path>
                </svg>
              )}

              <div>{item.title}</div>
            </div>
            <div
              className={`text-sm  transition-all duration-1000 ease-in-out ${
                itemFaq.includes(item.index) ? "my-4" : "hidden"
              }`}
            >
              {item.description}
            </div>
          </div>
        ))}
      </div>
      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default HomeScreen;
