export const FORMATION_LIST_REQUEST = "FORMATION_LIST_REQUEST";
export const FORMATION_LIST_SUCCESS = "FORMATION_LIST_SUCCESS";
export const FORMATION_LIST_FAIL = "FORMATION_LIST_FAIL";

export const FORMATION_ADD_REQUEST = "FORMATION_ADD_REQUEST";
export const FORMATION_ADD_SUCCESS = "FORMATION_ADD_SUCCESS";
export const FORMATION_ADD_FAIL = "FORMATION_ADD_FAIL";

export const FORMATION_DETAIL_REQUEST = "FORMATION_DETAIL_REQUEST";
export const FORMATION_DETAIL_SUCCESS = "FORMATION_DETAIL_SUCCESS";
export const FORMATION_DETAIL_FAIL = "FORMATION_DETAIL_FAIL";

export const FORMATION_UPDATE_REQUEST = "FORMATION_UPDATE_REQUEST";
export const FORMATION_UPDATE_SUCCESS = "FORMATION_UPDATE_SUCCESS";
export const FORMATION_UPDATE_FAIL = "FORMATION_UPDATE_FAIL";

export const FORMATION_DELETE_REQUEST = "FORMATION_DELETE_REQUEST";
export const FORMATION_DELETE_SUCCESS = "FORMATION_DELETE_SUCCESS";
export const FORMATION_DELETE_FAIL = "FORMATION_DELETE_FAIL";
