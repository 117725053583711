import React, { useEffect, useState } from "react";
import HeaderWeb from "../../layouts/HeaderWeb";
import FooterWeb from "../../layouts/FooterWeb";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";

function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPassword, setIsPassword] = useState(true);
  const [isCheck, setIsCheck] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const submitHandle = async (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Login Account
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto">
        <div className="md:w-[400px] w-full mx-auto my-30">
          <div className="font-bold text-3xl my-3">Sign In</div>
          <form onSubmit={submitHandle}>
            <div>
              {error && <Alert type="error" message={error} />}

              {loading && <h2 className="text-2xl mb-6">{loading} loading</h2>}
            </div>
            <div className="w-full  border border-[#dbe0e9]  rounded focus:border-[#227aff] outline-none  focus:border-1 my-3 ">
              <input
                type="email"
                value={email}
                required
                onChange={(v) => {
                  setEmail(v.target.value);
                }}
                className="px-4 py-2 w-full text-sm  outline-none "
                placeholder="Enter Your Email"
              />
            </div>
            <div className="w-full flex flex-row items-center border border-[#dbe0e9]  rounded focus:border-[#227aff] outline-none  focus:border-1  my-3 ">
              <input
                required
                type={isPassword ? "password" : "text"}
                value={password}
                onChange={(v) => {
                  setPassword(v.target.value);
                }}
                className="px-4 py-2 w-full text-sm flex-1  outline-none  "
                placeholder="Enter Your Password"
              />
              <button
                onClick={() => {
                  setIsPassword(!isPassword);
                }}
                className="mx-2 "
              >
                {!isPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 text-[#808c98] hover:text-black"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6 text-[#808c98] hover:text-black"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="my-3 flex flex-row justify-between items-center">
              <div className="flex flex-row cursor-pointer items-center">
                <input
                  type="checkbox"
                  checked={isCheck}
                  onClick={() => {
                    setIsCheck(!isCheck);
                  }}
                  id="remember"
                  class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label for="remember" class="ml-2 text-sm text-gray-700">
                  Remember me
                </label>
              </div>
              <div className="mx-4"></div>
              <button
                type="submit"
                className="text-white font-bold bg-primary px-4 py-2 rounded text-sm"
              >
                Sign In
              </button>
            </div>
          </form>
          <hr className="text-[#dbe0e9] my-2" />
          <div className="my-3 flex flex-row justify-between items-center">
            <div className="">
              <span className="text-sm">No account?</span>
              <a
                href="/register-account"
                className="text-primary text-sm font-bold"
              >
                {" "}
                Sign Up
              </a>
            </div>
            <div className="mx-4"></div>
            <a href="/forget-password" className="text-sm hover:text-primary">
              Lost Password?
            </a>
          </div>
        </div>
      </div>

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default LoginScreen;
