import React from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import SliderHome from "../components/SliderHome";
import FooterWeb from "../layouts/FooterWeb";

function OurClinicScreen() {
  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Our Clinics
            </div>
          </div>
        </div>
      </div>
      <div className="my-10 container mx-auto">
        <div className="flex md:flex-row flex-col">
          <div className="md:w-1/2 w-full">
            <div className="font-bold text-2xl my-2">HEAD OFFICE</div>
            <div className="font-bold ">Hijama Online Academy Ltd</div>
            <div className="my-3">
              Fairgate House
              <br />
              205 Kings Road, <br />
              Tyseley, Birmingham, B11 2AA
            </div>
            <div className="my-2">M | 07765 436 139</div>
            <div className="my-2">E | info@hijamaonlineacademy.com</div>
            <div className="mt-4 mb-2">
              Hijama Online Academy, just off the A45, a major road through the
              scenic Midlands.
            </div>
            <div className=" mb-2">
              It’s a short drive to Birmingham Airport, and just 10 minutes into
              Birmingham city centre.
            </div>
            <div className=" mb-2">
              For shopping and dining the famous Bullring Centre is a short
              drive away
            </div>
            <div className=" mb-2">At our Clinic you can enjoy:</div>
            <div className=" mb-2 ">
              <ul class="list-disc pl-5">
                <li class="text-gray-700">
                  Deli Bar:
                  <br />
                  The place to have breakfast, lunch, or a quick refreshment
                </li>
                <li class="text-gray-700">Secure on-site parking</li>
              </ul>
            </div>
            <div className=" mb-2">Excellent local transport links</div>
            <div className=" mb-2 ">
              <ul class="list-disc pl-5">
                <li class="text-gray-700">3 miles from the City Centre</li>
                <li class="text-gray-700">
                  0.2 miles to Tyseley Train Station
                </li>
                <li class="text-gray-700">6.5 miles from the M42</li>
                <li class="text-gray-700">6 miles to Birmingham Airport</li>
                <li class="text-gray-700">6 miles to NEC</li>
              </ul>
            </div>
          </div>
          <div className="md:w-1/2 w-full">
            <img
              className=" md:w-2/3 w-full mx-auto"
              src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRm91ci1jbGluaWNzLWhvYS5qcGcmY2FjaGVNYXJrZXI9MTcxMzEyMjI2Ny0yNDM4MSZ0b2tlbj01YzdhMThhZjM0MjYyNDg3.q.jpg"
            />
          </div>
        </div>
      </div>

      {/* slider */}
      <SliderHome />

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default OurClinicScreen;
