import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userLoginReducer } from "./reducers/userReducers";
import { detailFormationReducer } from "./reducers/formationReducers";

const reducer = combineReducers({
  // user
  userLogin: userLoginReducer,

  // formation
  detailFormation: detailFormationReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfoHijama")
  ? JSON.parse(localStorage.getItem("userInfoHijama"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
