import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function HeaderWeb() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  useEffect(() => {
    if (userInfo) {
    }
  }, [userInfo]);
  return (
    <div>
      <div className="bg-danger text-white py-3">
        <div className="container mx-auto text-sm">
          <div className="flex md:flex-row flex-col items-center justify-between ">
            {/* phone and email */}
            <div className="flex flex-row">
              <a
                href="tel:+212655806757"
                className="flex flex-row items-center  mx-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5 mx-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
                <div className="mx-1">(+212) 655 80 67 57</div>
              </a>
              <a
                href="mailto:aminelab50@gmail.com"
                className="flex flex-row mx-1 items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  class="size-5 mx-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>

                <div className="mx-1">aminelab50@gmail.com</div>
              </a>
            </div>
            {/*  */}
            <div className="flex flex-row">
              {userInfo ? (
                <a
                  href="/dashboard"
                  className="flex flex-row  mx-1 px-2 py-1 border rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 mx-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  <div className="mx-1">Dashboard</div>
                </a>
              ) : (
                <a
                  href="/login-account"
                  className="flex flex-row  mx-1 px-2 py-1 border rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 mx-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  <div className="mx-1">E-learning Login</div>
                </a>
              )}

              <div className="flex flex-row items-center mx-1">
                <svg
                  className="size-5 mx-1"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="size-5 mx-1"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-5 mx-1"
                  viewBox="0 0 93 92"
                  fill="currentColor"
                >
                  <rect
                    x="1.13867"
                    width="91.5618"
                    height="91.5618"
                    rx="15"
                    fill="#00D95F"
                  />
                  <path
                    d="M23.5068 66.8405L26.7915 54.6381C24.1425 49.8847 23.3009 44.3378 24.4211 39.0154C25.5413 33.693 28.5482 28.952 32.89 25.6624C37.2319 22.3729 42.6173 20.7554 48.0583 21.1068C53.4992 21.4582 58.6306 23.755 62.5108 27.5756C66.3911 31.3962 68.7599 36.4844 69.1826 41.9065C69.6053 47.3286 68.0535 52.7208 64.812 57.0938C61.5705 61.4668 56.8568 64.5271 51.5357 65.7133C46.2146 66.8994 40.6432 66.1318 35.8438 63.5513L23.5068 66.8405ZM36.4386 58.985L37.2016 59.4365C40.6779 61.4918 44.7382 62.3423 48.7498 61.8555C52.7613 61.3687 56.4987 59.5719 59.3796 56.7452C62.2605 53.9185 64.123 50.2206 64.6769 46.2279C65.2308 42.2351 64.445 38.1717 62.4419 34.6709C60.4388 31.1701 57.331 28.4285 53.6027 26.8734C49.8745 25.3184 45.7352 25.0372 41.8299 26.0736C37.9247 27.11 34.4729 29.4059 32.0124 32.6035C29.5519 35.801 28.2209 39.7206 28.2269 43.7514C28.2237 47.0937 29.1503 50.3712 30.9038 53.2192L31.3823 54.0061L29.546 60.8167L36.4386 58.985Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M54.9566 46.8847C54.5093 46.5249 53.9856 46.2716 53.4254 46.1442C52.8651 46.0168 52.2831 46.0186 51.7236 46.1495C50.8831 46.4977 50.3399 47.8134 49.7968 48.4713C49.6823 48.629 49.514 48.7396 49.3235 48.7823C49.133 48.8251 48.9335 48.797 48.7623 48.7034C45.6849 47.5012 43.1055 45.2965 41.4429 42.4475C41.3011 42.2697 41.2339 42.044 41.2557 41.8178C41.2774 41.5916 41.3862 41.3827 41.5593 41.235C42.165 40.6368 42.6098 39.8959 42.8524 39.0809C42.9063 38.1818 42.6998 37.2863 42.2576 36.5011C41.9157 35.4002 41.265 34.42 40.3825 33.6762C39.9273 33.472 39.4225 33.4036 38.9292 33.4791C38.4359 33.5546 37.975 33.7709 37.6021 34.1019C36.9548 34.6589 36.4411 35.3537 36.0987 36.135C35.7562 36.9163 35.5939 37.7643 35.6236 38.6165C35.6256 39.0951 35.6864 39.5716 35.8046 40.0354C36.1049 41.1497 36.5667 42.2144 37.1754 43.1956C37.6145 43.9473 38.0937 44.6749 38.6108 45.3755C40.2914 47.6767 42.4038 49.6305 44.831 51.1284C46.049 51.8897 47.3507 52.5086 48.7105 52.973C50.1231 53.6117 51.6827 53.8568 53.2237 53.6824C54.1018 53.5499 54.9337 53.2041 55.6462 52.6755C56.3588 52.1469 56.9302 51.4518 57.3102 50.6512C57.5334 50.1675 57.6012 49.6269 57.5042 49.1033C57.2714 48.0327 55.836 47.4007 54.9566 46.8847Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* menu */}
      <div className="bg-white shadow-1">
        <div className="container mx-auto py-2 ">
          <div className="flex flex-row justify-between items-center">
            {/* logo */}
            <a href="/">
              <img
                className=" size-30"
                src="https://hijamaonlineacademy.com/wp-content/uploads/HOA-logoP1.svg"
              />
            </a>
            {/*  */}
            <div className="md:flex flex-row items-center mx-1 hidden">
              <div className="relative mx-3 cursor-pointer text-black hover:text-danger  group">
                <a href="/" className="relative">
                  Home
                </a>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
              </div>
              <div className="mx-3 cursor-pointer flex justify-between items-center relative text-black hover:text-danger  group">
                <div className="relative">About</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5 mx-[2px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>

                {/* Submenu */}
                <div className="absolute z-99 left-0 top-full mt-2 w-max min-w-40 bg-white   shadow-lg rounded-md opacity-0 group-hover:opacity-100 scale-y-0 group-hover:scale-y-100 transform transition-all duration-300 origin-top">
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:text-danger text-black  ">
                      <a href="/our-story">Our Story</a>
                    </li>
                    <li className="px-4 py-2 hover:text-danger text-black ">
                      <a href="/why-choose-us">Why Choose Us</a>
                    </li>
                    <li className="px-4 py-2 hover:text-danger text-black ">
                      <a href="/our-clinics">Our Clinics</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mx-3 cursor-pointer flex justify-between items-center relative text-black hover:text-danger  group">
                <div className="relative">Hijama Therapy</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5 mx-[2px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                <div className="absolute z-99  left-0 top-full mt-2 w-max min-w-40 bg-white   shadow-lg rounded-md opacity-0 group-hover:opacity-100 scale-y-0 group-hover:scale-y-100 transform transition-all duration-300 origin-top">
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:text-danger text-black  w-fu;; ">
                      <a href="/authentic-narrations" className="w-max">
                        Authentic Narrations
                      </a>
                    </li>
                    <li className="px-4 py-2 hover:text-danger text-black ">
                      <a href="/what-is-hijama">What Is Hijama ?</a>
                    </li>
                    <li className="px-4 py-2 hover:text-danger text-black ">
                      <a href="/who-should-perform-hijama">
                        Who Should Perform Hijama ?
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mx-3 cursor-pointer flex justify-between items-center relative text-black hover:text-danger  group">
                <div className="relative">Hijama Training</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5 mx-[2px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                <div className="absolute z-99 left-0 top-full mt-2 w-max min-w-40 bg-white   shadow-lg rounded-md opacity-0 group-hover:opacity-100 scale-y-0 group-hover:scale-y-100 transform transition-all duration-300 origin-top">
                  <ul className="py-2">
                    <li className="px-4 py-2 hover:text-danger text-black  w-fu;; ">
                      <a href="/how-to-apply" className="w-max">
                        How To Apply
                      </a>
                    </li>
                    <li className="px-4 py-2 hover:text-danger text-black ">
                      <a href="/entry-requirements">Entry Requirements</a>
                    </li>
                    <li className="px-4 py-2 hover:text-danger text-black ">
                      <a href="/syllabus">Syllabus</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="relative mx-3 cursor-pointer text-black hover:text-danger  group">
                <a href="/pricing" className="relative">
                  Pricing
                </a>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
              </div>
              <div className="relative mx-3 cursor-pointer text-black hover:text-danger  group">
                <a href="/contact-us" className="relative">
                  Contact
                </a>
                <div className="absolute left-0 right-0 -bottom-2 h-[2px] bg-danger scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
              </div>
            </div>
            {/* mobile */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderWeb;
