// export const baseURL = "https://api.tassyer.com/api";
// export const baseURLFile = "https://api.tassyer.com";

export const baseURL = "http://192.168.100.153:8000/api";
export const baseURLFile = "http://192.168.100.153:8000";

// export const baseURL = "http://15.188.52.23/api";
// export const baseURLFile = "http://15.188.52.23";

export const CHOOSUS = [
  {
    index: 1,
    title: "Professional Expertise:",
    description:
      "We pride ourselves on being a professional hijama training provider. Our courses are meticulously designed and delivered by experts in the field. You can trust that you are receiving instruction from seasoned professionals with a wealth of knowledge and experience in hijama therapy. Our trainers have years of experience in performing hijama therapy. From traditional cupping techniques to more specialised procedures, our team is well-versed in a variety of approaches to address different health concerns.",
  },
  {
    index: 2,
    title: "Comprehensive Curriculum:",
    description:
      "Our training programs cover a wide range of topics related to hijama therapy. From the fundamentals to advanced techniques, our curriculum is designed to provide you with a thorough understanding of hijama, ensuring you are well-equipped to practice this ancient therapeutic art professionally.",
  },
  {
    index: 3,
    title: "Hands-On Training:",
    description:
      "We believe in learning by doing. Our courses include practical, hands-on training to give you a real-world feel of hijama therapy. This practical experience is invaluable for gaining confidence and proficiency in performing hijama procedures.",
  },
  {
    index: 4,
    title: "Recognised Certification:",
    description:
      "Upon successful completion of our courses, you will receive a certification that is recognised within our industry. Our certificates attest to your proficiency and competence as a hijama practitioner, enhancing your credibility in the field.",
  },
  {
    index: 5,
    title: "Ethical and Professional Standards:",
    description:
      "As a professional hijama training provider, we uphold the highest ethical and professional standards. Our courses emphasise the importance of adhering to ethical practices in hijama therapy, ensuring that you provide safe and effective treatments to your clients.",
  },
  {
    index: 6,
    title: "Ongoing Support:",
    description:
      "Learning doesn’t end with the completion of a course. We provide ongoing 1-2-1 support to our students. Whether you have questions about specific techniques, need advice on setting up your practice, or seek guidance on challenging cases, our team is here to support you on your hijama journey.",
  },
  {
    index: 7,
    title: "Community and Networking:",
    description:
      "Joining our training programs means becoming part of a community of like-minded individuals. Connect with fellow students, practitioners, and experts in the field. Networking opportunities can open doors to collaboration, mentorship, and continuous learning.",
  },
  {
    index: 8,
    title: "Career Development:",
    description:
      "We are committed to your success. Our courses are designed not only to teach hijama techniques but also to equip you with the skills needed to succeed in your career. From marketing your practice to managing client relationships, we provide insights into various aspects of building a successful hijama therapy business.",
  },
  {
    index: 9,
    title: "No Shortcut Trainings:",
    description:
      "Unlike some providers who offer quick, shortcut training programs, our practitioners undergo comprehensive and in-depth training. We believe in the value of expertise, and our commitment to ongoing education ensures that our team stays updated with the latest developments in hijama therapy.",
  },
  {
    index: 10,
    title: "Certified Practitioners:",
    description:
      "All our hijama practitioners are certified professionals with extensive training in the field. We prioritise education and skill development to ensure that our team delivers safe and effective hijama treatments.",
  },
];

export const LISTFAQ = [
  {
    index: 1,
    title: "What qualifications do I need to enroll in a Hijama course?",
    description:
      "Typically, there are no specific qualifications required to enroll on our Online Hijama course. However, some providers may have prerequisites.",
  },
  {
    index: 2,
    title: "How long does it take to complete a Hijama course?",
    description:
      "The duration of a Hijama course can vary depending on the provider and the level of the course. Our Online Hijama course may be completed in a three weeks.  There are no deadlines for this course as you can study it at your own pace and in your own time.",
  },
  {
    index: 3,
    title: "Why should I choose Hijama Online Academy (HOA)?",
    description: "Toggle Content",
  },
  {
    index: 4,
    title:
      "Summarise what is HOA's structure to gaining a Diploma qualification?",
    description:
      "Sign up, Complete the modules, Pass the online open book exam, Attend a 2 day live practical workshop classe at our clinic in Birmingham, – UK, Receive your Diploma, open your new business.",
  },
  {
    index: 5,
    title: "How do I choose the right Hijama course provider?",
    description:
      "When selecting a Hijama course provider, consider factors such as the provider’s reputation, the curriculum offered, accreditation status, instructor qualifications, mode of delivery (online or in-person), cost, and available support services. It’s essential to choose a provider that aligns with your learning goals and preferences.",
  },
  {
    index: 6,
    title: "What ongoing support is available after completing the course?",
    description:
      "Many Hijama course providers offer ongoing support to their graduates, which may include access to resources, forums, or mentorship opportunities. Additionally, some providers may offer refresher courses or advanced training for continued professional development.",
  },
  {
    index: 7,
    title: "Can I start my own Hijama practice after completing the course?",
    description:
      "Yes, upon completion of a Hijama course, you will have the necessary knowledge and skills to start your own practice.",
  },
  {
    index: 8,
    title: "Will I receive a certification upon completion of the course?",
    description:
      "Upon successful completion of the course, we offer you a Diploma certificate. This certification validates your training and competence as a Hijama Practitioner.",
  },
];

export const COUNTRIES = [
  {
    title: "Afghanistan",
    value: "AF",
  },
  {
    title: "Albania",
    value: "AL",
  },
  {
    title: "Algeria",
    value: "DZ",
  },
  {
    title: "American Samoa",
    value: "AS",
  },
  {
    title: "Andorra",
    value: "AD",
  },
  {
    title: "Angola",
    value: "AO",
  },
  {
    title: "Anguilla",
    value: "AI",
  },
  {
    title: "Argentina",
    value: "AR",
  },
  {
    title: "Armenia",
    value: "AM",
  },
  {
    title: "Aruba",
    value: "AW",
  },
  {
    title: "Australia",
    value: "AU",
  },
  {
    title: "Azerbaijan",
    value: "AZ",
  },
  {
    title: "Bahamas",
    value: "BS",
  },
  {
    title: "Bahrain",
    value: "BH",
  },
  {
    title: "Bangladesh",
    value: "BD",
  },
  {
    title: "Barbados",
    value: "BB",
  },
  {
    title: "Belarus",
    value: "BY",
  },
  {
    title: "Belgium",
    value: "BE",
  },
  {
    title: "Belize",
    value: "BZ",
  },
  {
    title: "Benin",
    value: "BJ",
  },
  {
    title: "Bermuda",
    value: "BM",
  },
  {
    title: "Bhutan",
    value: "BT",
  },
  {
    title: "Bolivia",
    value: "BO",
  },
  {
    title: "Bosnia and Herzegovina",
    value: "BA",
  },
  {
    title: "Botswana",
    value: "BW",
  },
  {
    title: "Brazil",
    value: "BR",
  },
  {
    title: "British Virgin Islands",
    value: "VG",
  },
  {
    title: "Brunei",
    value: "BN",
  },
  {
    title: "Bulgaria",
    value: "BG",
  },
  {
    title: "Burkina Faso",
    value: "BF",
  },
  {
    title: "Burundi",
    value: "BI",
  },
  {
    title: "Cambodia",
    value: "KH",
  },
  {
    title: "Cameroon",
    value: "CM",
  },
  {
    title: "Canada",
    value: "CA",
  },
  {
    title: "Cape Verde",
    value: "CV",
  },
  {
    title: "Cayman Islands",
    value: "KY",
  },
  {
    title: "Central African Republic",
    value: "CF",
  },
  {
    title: "Chad",
    value: "TD",
  },
  {
    title: "Chile",
    value: "CL",
  },
  {
    title: "China",
    value: "CN",
  },
  {
    title: "Columbia",
    value: "CO",
  },
  {
    title: "Comoros",
    value: "KM",
  },
  {
    title: "Cook Islands",
    value: "CK",
  },
  {
    title: "Costa Rica",
    value: "CR",
  },
  {
    title: "Croatia",
    value: "HR",
  },
  {
    title: "Cuba",
    value: "CU",
  },
  {
    title: "Curacao",
    value: "CW",
  },
  {
    title: "Cyprus",
    value: "CY",
  },
  {
    title: "Czech Republic",
    value: "CZ",
  },
  {
    title: "Democratic Republic of the Congo",
    value: "CD",
  },
  {
    title: "Denmark",
    value: "DK",
  },
  {
    title: "Djibouti",
    value: "DJ",
  },
  {
    title: "Dominica",
    value: "DM",
  },
  {
    title: "Dominican Republic",
    value: "DO",
  },
  {
    title: "East Timor",
    value: "TL",
  },
  {
    title: "Ecuador",
    value: "EC",
  },
  {
    title: "Egypt",
    value: "EG",
  },
  {
    title: "El Salvador",
    value: "SV",
  },
  {
    title: "Eritrea",
    value: "ER",
  },
  {
    title: "Estonia",
    value: "EE",
  },
  {
    title: "Ethiopia",
    value: "ET",
  },
  {
    title: "Faroe Islands",
    value: "FO",
  },
  {
    title: "Fiji",
    value: "FJ",
  },
  {
    title: "Finland",
    value: "FI",
  },
  {
    title: "France",
    value: "FR",
  },
  {
    title: "French Polynesia",
    value: "PF",
  },
  {
    title: "Gabon",
    value: "GA",
  },
  {
    title: "Gambia",
    value: "GM",
  },
  {
    title: "Georgia",
    value: "GE",
  },
  {
    title: "Germany",
    value: "DE",
  },
  {
    title: "Ghana",
    value: "GH",
  },
  {
    title: "Greece",
    value: "GR",
  },
  {
    title: "Greenland",
    value: "GL",
  },
  {
    title: "Grenada",
    value: "GD",
  },
  {
    title: "Guam",
    value: "GU",
  },
  {
    title: "Guatemala",
    value: "GT",
  },
  {
    title: "Guernsey",
    value: "GG",
  },
  {
    title: "Guinea",
    value: "GN",
  },
  {
    title: "Guinea-Bissau",
    value: "GW",
  },
  {
    title: "Guyana",
    value: "GY",
  },
  {
    title: "Haiti",
    value: "HT",
  },
  {
    title: "Honduras",
    value: "HN",
  },
  {
    title: "Hong Kong",
    value: "HK",
  },
  {
    title: "Hungary",
    value: "HU",
  },
  {
    title: "Iceland",
    value: "IS",
  },
  {
    title: "India",
    value: "IN",
  },
  {
    title: "Indonesia",
    value: "ID",
  },
  {
    title: "Iran",
    value: "IR",
  },
  {
    title: "Iraq",
    value: "IQ",
  },
  {
    title: "Ireland",
    value: "IE",
  },
  {
    title: "Isle of Man",
    value: "IM",
  },
  {
    title: "Israel",
    value: "IL",
  },
  {
    title: "Italy",
    value: "IT",
  },
  {
    title: "Ivory Coast",
    value: "CI",
  },
  {
    title: "Jamaica",
    value: "JM",
  },
  {
    title: "Japan",
    value: "JP",
  },
  {
    title: "Jersey",
    value: "JE",
  },
  {
    title: "Jordan",
    value: "JO",
  },
  {
    title: "Kazakhstan",
    value: "KZ",
  },
  {
    title: "Kenya",
    value: "KE",
  },
  {
    title: "Kiribati",
    value: "KI",
  },
  {
    title: "Kosovo",
    value: "XK",
  },
  {
    title: "Kuwait",
    value: "KW",
  },
  {
    title: "Kyrgyzstan",
    value: "KG",
  },
  {
    title: "Laos",
    value: "LA",
  },
  {
    title: "Latvia",
    value: "LV",
  },
  {
    title: "Lebanon",
    value: "LB",
  },
  {
    title: "Lesotho",
    value: "LS",
  },
  {
    title: "Liberia",
    value: "LB",
  },
  {
    title: "Libya",
    value: "LY",
  },
  {
    title: "Liechtenstein",
    value: "LI",
  },
  {
    title: "Lithuania",
    value: "LT",
  },
  {
    title: "Luxembourg",
    value: "LU",
  },
  {
    title: "Macau",
    value: "MO",
  },
  {
    title: "Macedonia",
    value: "MK",
  },
  {
    title: "Madagascar",
    value: "MG",
  },
  {
    title: "Malawi",
    value: "MW",
  },
  {
    title: "Malaysia",
    value: "MY",
  },
  {
    title: "Maldives",
    value: "MV",
  },
  {
    title: "Mali",
    value: "ML",
  },
  {
    title: "Malta",
    value: "MT",
  },
  {
    title: "Marshall Islands",
    value: "MH",
  },
  {
    title: "Mauritania",
    value: "MR",
  },
  {
    title: "Mauritius",
    value: "MU",
  },
  {
    title: "Mayotte",
    value: "YT",
  },
  {
    title: "Mexico",
    value: "MX",
  },
  {
    title: "Micronesia",
    value: "FM",
  },
  {
    title: "Moldova",
    value: "MD",
  },
  {
    title: "Monaco",
    value: "MC",
  },
  {
    title: "Mongolia",
    value: "MN",
  },
  {
    title: "Montenegro",
    value: "ME",
  },
  {
    title: "Morocco",
    value: "MA",
  },
  {
    title: "Mozambique",
    value: "MZ",
  },
  {
    title: "Myanmar",
    value: "MM",
  },
  {
    title: "Namibia",
    value: "NA",
  },
  {
    title: "Nepal",
    value: "NP",
  },
  {
    title: "Netherlands",
    value: "NL",
  },
  {
    title: "Netherlands Antilles",
    value: "AN",
  },
  {
    title: "New Caledonia",
    value: "NC",
  },
  {
    title: "New Zealand",
    value: "NZ",
  },
  {
    title: "Nicaragua",
    value: "NI",
  },
  {
    title: "Niger",
    value: "NE",
  },
  {
    title: "Nigeria",
    value: "NG",
  },
  {
    title: "North Korea",
    value: "KP",
  },
  {
    title: "Northern Mariana Islands",
    value: "MP",
  },
  {
    title: "Norway",
    value: "NO",
  },
  {
    title: "Oman",
    value: "OM",
  },
  {
    title: "Pakistan",
    value: "PK",
  },
  {
    title: "Palestine",
    value: "PS",
  },
  {
    title: "Panama",
    value: "PA",
  },
  {
    title: "Papua New Guinea",
    value: "PG",
  },
  {
    title: "Paraguay",
    value: "PY",
  },
  {
    title: "Peru",
    value: "PE",
  },
  {
    title: "Philippines",
    value: "PH",
  },
  {
    title: "Poland",
    value: "PL",
  },
  {
    title: "Portugal",
    value: "PT",
  },
  {
    title: "Puerto Rico",
    value: "PR",
  },
  {
    title: "Qatar",
    value: "QA",
  },
  {
    title: "Republic of the Congo",
    value: "CG",
  },
  {
    title: "Reunion",
    value: "RE",
  },
  {
    title: "Romania",
    value: "RO",
  },
  {
    title: "Russia",
    value: "RU",
  },
  {
    title: "Rwanda",
    value: "RW",
  },
  {
    title: "Saint Kitts and Nevis",
    value: "KN",
  },
  {
    title: "Saint Lucia",
    value: "LC",
  },
  {
    title: "Saint Martin",
    value: "MF",
  },
  {
    title: "Saint Pierre and Miquelon",
    value: "PM",
  },
  {
    title: "Saint Vincent and the Grenadines",
    value: "VC",
  },
  {
    title: "Samoa",
    value: "WS",
  },
  {
    title: "San Marino",
    value: "SM",
  },
  {
    title: "Sao Tome and Principe",
    value: "ST",
  },
  {
    title: "Saudi Arabia",
    value: "SA",
  },
  {
    title: "Senegal",
    value: "SN",
  },
  {
    title: "Serbia",
    value: "RS",
  },
  {
    title: "Seychelles",
    value: "SC",
  },
  {
    title: "Sierra Leone",
    value: "SL",
  },
  {
    title: "Singapore",
    value: "SG",
  },
  {
    title: "Sint Maarten",
    value: "SX",
  },
  {
    title: "Slovakia",
    value: "SK",
  },
  {
    title: "Slovenia",
    value: "SI",
  },
  {
    title: "Solomon Islands",
    value: "SB",
  },
  {
    title: "Somalia",
    value: "SO",
  },
  {
    title: "South Africa",
    value: "ZA",
  },
  {
    title: "South Korea",
    value: "KR",
  },
  {
    title: "South Sudan",
    value: "SS",
  },
  {
    title: "Spain",
    value: "ES",
  },
  {
    title: "Sri Lanka",
    value: "LK",
  },
  {
    title: "Sudan",
    value: "SD",
  },
  {
    title: "Suriname",
    value: "SR",
  },
  {
    title: "Swaziland",
    value: "SZ",
  },
  {
    title: "Sweden",
    value: "SE",
  },
  {
    title: "Switzerland",
    value: "CH",
  },
  {
    title: "Syria",
    value: "SY",
  },
  {
    title: "Taiwan",
    value: "TW",
  },
  {
    title: "Tajikistan",
    value: "TJ",
  },
  {
    title: "Tanzania",
    value: "TZ",
  },
  {
    title: "Thailand",
    value: "TH",
  },
  {
    title: "Togo",
    value: "TG",
  },
  {
    title: "Tonga",
    value: "TO",
  },
  {
    title: "Trinidad and Tobago",
    value: "TT",
  },
  {
    title: "Tunisia",
    value: "TN",
  },
  {
    title: "Turkey",
    value: "TR",
  },
  {
    title: "Turkmenistan",
    value: "TM",
  },
  {
    title: "Turks and Caicos Islands",
    value: "TC",
  },
  {
    title: "Tuvalu",
    value: "TV",
  },
  {
    title: "U.S. Virgin Islands",
    value: "VI",
  },
  {
    title: "Uganda",
    value: "UG",
  },
  {
    title: "Ukraine",
    value: "UA",
  },
  {
    title: "United Arab Emirates",
    value: "AE",
  },
  {
    title: "United Kingdom",
    value: "GB",
  },
  {
    title: "United States",
    value: "US",
  },
  {
    title: "Uruguay",
    value: "UY",
  },
  {
    title: "Uzbekistan",
    value: "UZ",
  },
  {
    title: "Vanuatu",
    value: "VU",
  },
  {
    title: "Venezuela",
    value: "VE",
  },
  {
    title: "Vietnam",
    value: "VN",
  },
  {
    title: "Western Sahara",
    value: "EH",
  },
  {
    title: "Yemen",
    value: "YE",
  },
  {
    title: "Zambia",
    value: "ZM",
  },
  {
    title: "Zimbabwe",
    value: "ZW",
  },
];
