import React, { useEffect, useState } from "react";
import HeaderWeb from "../../layouts/HeaderWeb";
import FooterWeb from "../../layouts/FooterWeb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailFormation } from "../../redux/actions/formationActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

function CourseScreen() {
  const dispatch = useDispatch();
  let { id } = useParams();

  const [selectPage, setSelectPage] = useState("Description");

  const formationDetail = useSelector((state) => state.detailFormation);
  const { loadingFormation, errorFormation, successFormation, formation } =
    formationDetail;

  useEffect(() => {
    dispatch(detailFormation(id));
  }, [dispatch, id]);

  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div className="my-20 container mx-auto">
        {loadingFormation ? (
          <Loader />
        ) : errorFormation ? (
          <Alert type="error" message={errorFormation} />
        ) : (
          <div>
            <div className="flex md:flex-row flex-col">
              <div className="md:w-2/3 w-full px-5">
                <div className="mb-5 font-bold text-2xl ">
                  {formation?.title_formation}
                </div>
                <div className="flex flex-row my-1">
                  {formation?.teacher_formation ? (
                    <div className="flex flex-row items-center mx-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className=" size-10"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                      <div className="flex-1 mx-1">
                        <div className="text-sm">Teacher</div>
                        <div className="text-sm font-bold">
                          {formation?.teacher_formation}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {formation?.category_formation ? (
                    <div className="flex flex-row items-center mx-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-10"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                        />
                      </svg>

                      <div className="flex-1 mx-1">
                        <div className="text-sm">Category</div>
                        <div className="text-sm font-bold">
                          {formation?.category_formation}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="my-1">
                  <div className="flex flex-row justify-around bg-primary w-full pt-1 text-white text-xs my-5">
                    <div
                      onClick={() => {
                        setSelectPage("Description");
                      }}
                      className={`${
                        selectPage === "Description"
                          ? "bg-white text-primary"
                          : ""
                      } w-full flex-1 py-3 text-center cursor-pointer`}
                    >
                      Description
                    </div>
                    <div
                      onClick={() => {
                        setSelectPage("Curriculum");
                      }}
                      className={`${
                        selectPage === "Curriculum"
                          ? "bg-white text-primary"
                          : ""
                      } w-full flex-1 py-3 text-center cursor-pointer`}
                    >
                      Curriculum
                    </div>
                  </div>

                  {selectPage === "Description" ? (
                    <div className="">
                      <img
                        className=" mx-auto  "
                        src="https://hijamaonlineacademy.com/wp-content/uploads/HOA-logoP1.svg"
                      />
                      <div className="mt-5">
                        {formation?.description_formation ?? ""}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {formation?.curriculum_list?.map((model, index) => (
                        <div>
                          <div
                            onClick={() => {}}
                            className="font-medium my-4 flex flex-row items-center cursor-pointer"
                          >
                            <div>
                              Module {index + 1} - {model.title_module ?? ""}
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="size-5 rounded-full p-1 bg-primary text-white mx-3"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m4.5 15.75 7.5-7.5 7.5 7.5"
                              />
                            </svg>
                          </div>
                          {/* items */}
                          {model.course_list?.map((item, indexItem) => (
                            <div className="flex flex-row bg-gray my-2 py-3 px-2 items-center rounded">
                              <div className="text-sm">{indexItem + 1}</div>
                              <img
                                width="100"
                                height="100"
                                className="size-6 mx-3"
                                src="https://img.icons8.com/plasticine/100/file.png"
                                alt="file"
                              />
                              <div className="flex-1 flex md:flex-row flex-col">
                                <div className="flex-1 text-sm font-bold">
                                  {item.title_course}
                                </div>
                                <div className="text-sm text-graydark">
                                  {item.type_course === "VIDEO"
                                    ? item.video_dure
                                    : "Text lesson"}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="md:w-1/3  w-full px-5">
                {/* wish  */}
                <div className="bg-danger text-white flex flex-row px-4 py-5 rounded-md mt-5 items-center cursor-pointer">
                  <div className="flex-1 font-bold"> Get course </div>
                  <div className=" text-right ">
                    {formation?.is_promotion ? (
                      <div>
                        <div className=" line-through text-gray  ">
                          {formation?.price_formation} $
                        </div>
                        <div className="  ">
                          {formation?.price_formation -
                            (formation?.price_formation *
                              formation.promotion_formation) /
                              100}{" "}
                          $
                        </div>
                      </div>
                    ) : (
                      <div>{formation?.price_formation} $</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default CourseScreen;
