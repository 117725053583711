import React from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";
import SliderHome from "../components/SliderHome";

function OurStoryScreen() {
  return (
    <div className="bg-white text-black ">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Our Story
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto my-6 ">
        <div className="flex md:flex-row flex-col items-center">
          <div className="md:w-1/2 w-full">
            <div>
              In 2014, I embarked on my journey in Hijama by undergoing training
              at an institute, and soon after, I established my Hijama Clinic in
              Birmingham. A decade later, reflecting on our growth, we have
              proudly inaugurated our own Hijama training academy.
            </div>
            <div>
              The evolution of our venture has been remarkable. Commencing as a
              local Hijama Clinic in 2014, we have transformed into a prominent
              online learning provider. Today, as the Hijama Online Academy, we
              stand ready to offer a unique learning experience through our
              online Hijama Diploma.
            </div>
            <div>
              With a decade of experience in Hijama practice, teaching, and
              research, I continue to be awe-inspired by the transformative
              power of Hijama. Witnessing countless individuals transition from
              pain and suffering to happiness and fulfillment has been a
              profound journey.
            </div>
          </div>
          <div className="md:m-4 my-4">
            <img src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRmFib3V0LUhPQS5qcGcmY2FjaGVNYXJrZXI9MTcxMzEyMTgzOC0yNjM3NyZ0b2tlbj0wOGJmYTk0NTBhNzVlOTk4.q.jpg" />
          </div>
        </div>
        <div className="">
          Our approach at Hijama Online Academy is distinctive; we believe in
          doing things differently and better. Recognising the dynamic landscape
          of education and the evolving global economy, we aim to proactively
          meet the current and future needs of both employers and learners. Our
          commitment is to provide life-changing diploma qualifications to
          students, irrespective of their background or personal circumstances.
        </div>
        <div>
          Championing equality, diversity, and inclusion, we view further
          education as a catalyst for social and economic change. By leveraging
          technology, we are revolutionising the traditional learning model,
          breaking down unnecessary barriers to education.
        </div>
        <div>
          The core vision of HOA is to revive a forgotten Sunnah, ensuring that
          highly skilled certified practitioners are equipped to offer the
          benefits of Hijama to anyone seeking its advantages. Our mission is to
          make quality Hijama practices accessible to all, contributing to a
          healthier and more informed society.
        </div>
      </div>
      {/*  */}
      {/* slider */}
      <SliderHome />

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default OurStoryScreen;
