import React, { useState } from "react";
import FooterWeb from "../../layouts/FooterWeb";
import HeaderWeb from "../../layouts/HeaderWeb";

function ForgetPasswordScreen() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Lost Password
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto">
        <div className="md:w-[400px] w-full mx-auto my-30">
          <div className="font-bold text-3xl my-3">Restore password</div>
          <div className="w-full  border border-[#dbe0e9]  rounded focus:border-[#227aff] outline-none  focus:border-1 my-2 ">
            <input
              type="email"
              value={email}
              onChange={(v) => {
                setEmail(v.target.value);
              }}
              className="px-4 py-2 w-full text-sm  outline-none "
              placeholder="Enter Your Email"
            />
          </div>

          <div className="my-3 flex flex-row justify-between items-center">
            <button className="text-white font-bold bg-primary px-4 py-2 rounded text-sm">
              Confirm
            </button>
          </div>
          <hr className="text-[#dbe0e9] my-2" />
          <div className="my-3 flex flex-row justify-between items-center">
            <div className="">
              <span className="text-sm">Have account?</span>
              <a
                href="/login-account"
                className="text-primary text-sm font-bold"
              >
                {" "}
                Sign In
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default ForgetPasswordScreen;
