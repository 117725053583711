import React, { useState } from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";
import { LISTFAQ } from "../constants";

function FaqScreen() {
  const [itemFaq, setItemFaq] = useState([]);
  return (
    <div className="bg-white text-black min-h-screen">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              FAQ's
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto mt-4">
        {LISTFAQ?.map((item, index) => (
          <div
            onClick={() => {
              if (itemFaq.includes(item.index)) {
                setItemFaq(itemFaq.filter((i) => i !== item.index));
              } else {
                setItemFaq([...itemFaq, item.index]);
              }
            }}
            className="my-4  cursor-pointer border-b py-2 border-[#d5d8dc]"
          >
            <div className=" text-base font-bold text-black flex flex-row items-center">
              {itemFaq.includes(item.index) ? (
                <svg
                  class="size-4 mx-1"
                  viewBox="0 0 320 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current"
                    d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"
                  ></path>
                </svg>
              ) : (
                <svg
                  class="size-4 mx-1"
                  viewBox="0 0 192 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current"
                    d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
                  ></path>
                </svg>
              )}

              <div>{item.title}</div>
            </div>
            <div
              className={`text-sm  transition-all duration-1000 ease-in-out ${
                itemFaq.includes(item.index) ? "my-4" : "hidden"
              }`}
            >
              {item.description}
            </div>
          </div>
        ))}
      </div>

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default FaqScreen;
