import React from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SliderHome from "../components/SliderHome";

function WhatIsHijamaScreen() {
  return (
    <div className="bg-white text-black min-h-screen">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              What is Hijama?
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto my-10 ">
        <div className="flex md:flex-row flex-col my-2 items-center">
          <div className="md:w-1/2 w-full p-2">
            <div className=" font-bold text-3xl">Hijama / Cupping</div>
            <div className="mt-3 ">
              Hijama, also known as cupping therapy, is an ancient medical
              practice that involves placing cups on the skin to create suction.
              The cups can be made of various materials, such as glass, bamboo,
              or silicone. The suction is typically created by heating the air
              inside the cup and placing it on the skin, causing a vacuum
              effect.
            </div>
          </div>
          <div className="md:w-1/2 w-full p-2">
            <img src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRndoYXQtaXMtaGlqYW1hLTEuanBnJmNhY2hlTWFya2VyPTE3MTMxMjM3MDUtMTIxNzgmdG9rZW49ZThmMjlmMDY2MjJjZjE5ZA.q.jpg" />
          </div>
        </div>
        <div className="flex md:flex-row flex-col-reverse  my-3 items-center">
          <div className="md:w-1/2 w-full p-2">
            <img src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRndoYXQtaXMtaGlqYW1hLTIuanBnJmNhY2hlTWFya2VyPTE3MTMxMjM4MTMtODc1NSZ0b2tlbj02MzY5ODVkNjc4ODIzZDQx.q.jpg" />
          </div>
          <div className="md:w-1/2 w-full p-2">
            <div className=" font-bold text-3xl">Hijama Types</div>
            <div className="mt-3 ">
              There are two main types of cupping: dry cupping and wet cupping.
              In dry cupping, the cups are applied to the skin to create
              suction, which is believed to stimulate blood flow and promote
              healing. Wet cupping involves making small incisions on the skin
              before applying the cups, allowing a small amount of blood to be
              drawn into the cups.
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col  my-3 items-center">
          <div className="md:w-1/2 w-full p-2">
            <div className="font-bold text-3xl">Ancient Therapy</div>
            <div className="mt-3">
              Hijama has been practiced in various cultures throughout history
              and is often associated with traditional medicine. Some proponents
              believe that hijama can help with various health issues, including
              pain relief, inflammation reduction, and detoxification.
            </div>
          </div>
          <div className="md:w-1/2 w-full p-2">
            <img src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRndoYXQtaXMtaGlqYW1hLTMuanBnJmNhY2hlTWFya2VyPTE3MTMxMjQxMTYtOTk2NSZ0b2tlbj03MTAxYWI5NTI3M2JmZjVh.q.jpg" />
          </div>
        </div>
      </div>
      {/* carousel */}
      <SliderHome />

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default WhatIsHijamaScreen;
