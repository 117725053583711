import React from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";
import SliderHome from "../components/SliderHome";

function WhoShouldPerformHijamaScreen() {
  return (
    <div className="bg-white text-black min-h-screen">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Who Should Perform Hijama?
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="container mx-auto my-10">
        <div className="flex md:flex-row my-3 flex-col items-center">
          <div className="md:w-1/2 px-3 w-full">
            <div className="font-bold text-2xl my-4">Who should perform?</div>
            <div>
              The question of who should perform Hijama (cupping therapy) is a
              subject of ongoing debate within the practical sphere of this
              traditional therapy. The discussion revolves around whether it
              should be exclusively practiced by qualified doctors or if it can
              be carried out at home by family members and laypeople. The stakes
              are high, considering that health is a valuable asset, second only
              to spirituality.
            </div>
          </div>
          <div className="md:w-1/2 px-3 w-full my-2">
            <img src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRndoby1zaG91bGQtcGVyZm9ybS1oaWphbWEtMS5qcGcmY2FjaGVNYXJrZXI9MTcxMzEyNDUxMS0xMjM1NSZ0b2tlbj1kMTVjMDM4YjAzZDRiODhk.q.jpg" />
          </div>
        </div>
        {/*  */}
        <div className="flex md:flex-row my-3 flex-col-reverse items-center">
          <div className="md:w-1/2 px-3 w-full my-2">
            <img src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRndoby1zaG91bGQtcGVyZm9ybS1oaWphbWEtMi5qcGcmY2FjaGVNYXJrZXI9MTcxMzEyNDU3MC0xMTQ1MSZ0b2tlbj1kZWMzNjQ0NDliOTlmM2Y4.q.jpg" />
          </div>
          <div className="md:w-1/2 px-3 w-full">
            <div className="font-bold text-2xl my-4">The practitioner</div>
            <div>
              The decision on who can be entrusted with the practice of Hijama
              involves assessing the competence, knowledge, and trustworthiness
              of the practitioner. Analogous to seeking assistance in various
              areas of life, individuals tend to prefer experts with relevant
              knowledge and experience. In the realm of health, the ideal choice
              is someone with a comprehensive understanding of the body and its
              functioning.
            </div>
          </div>
        </div>
        {/*  */}
        <div className="flex md:flex-row my-3 flex-col items-center">
          <div className="md:w-1/2 px-3 w-full">
            <div className="font-bold text-2xl my-4">Training</div>
            <div>
              The article emphasises the importance of turning to professionals
              with extensive training in health and medicine for Hijama therapy.
              However, it acknowledges the current dilemma: there is a shortage
              of health professionals who appreciate and practice Hijama, and
              many lay practitioners are filling this gap. This situation raises
              concerns about patient safety, potential risks, and the lack of a
              regulatory framework for non-professional practitioners.
            </div>
          </div>
          <div className="md:w-1/2 px-3 w-full my-2">
            <img src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRndoby1zaG91bGQtcGVyZm9ybS1oaWphbWEtMy5qcGcmY2FjaGVNYXJrZXI9MTcxMzEyNDY0NS01Njc1JnRva2VuPWI2ZWJlMWYzYjYyYTY5YTM.q.jpg" />
          </div>
        </div>
        {/*  */}
        <div className="flex md:flex-row my-3 flex-col-reverse items-center">
          <div className="md:w-1/2 px-3 w-full my-2">
            <img src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRndoby1zaG91bGQtcGVyZm9ybS1oaWphbWEtNC5qcGcmY2FjaGVNYXJrZXI9MTcxMzEyNDcwMi0xMDI0MCZ0b2tlbj1iNzI5NmQ2MzgwZDdhMWU0.q.jpg" />
          </div>
          <div className="md:w-1/2 px-3 w-full">
            <div className="font-bold text-2xl my-4">
              Training at Hijama Online Academy (HOA)
            </div>
            <div>
              At HOA, our proposed solution involves establishing clinic setups
              whereby students are trained professionally, receive experience,
              get qualified, certificated & are accredited.
              <br />
              <br />
              HOA’s model allows for proper patient assessment, screening, and
              management by qualified health professionals. Our approach aims to
              ensure patient safety, enhance practitioner credibility, and
              create a system of mutual learning.
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      {/* slider */}
      <SliderHome />

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default WhoShouldPerformHijamaScreen;
