import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import HomeScreen from "./screens/HomeScreen.js";
import ContactScreen from "./screens/ContactScreen.js";
import PricingScreen from "./screens/PricingScreen.js";
import SyllabusScreen from "./screens/SyllabusScreen.js";
import EntryRequirementScreen from "./screens/EntryRequirementScreen.js";
import HowToApplyScreen from "./screens/HowToApplyScreen.js";
import WhatIsHijamaScreen from "./screens/WhatIsHijamaScreen.js";
import WhoShouldPerformHijamaScreen from "./screens/WhoShouldPerformHijamaScreen.js";
import OurStoryScreen from "./screens/OurStoryScreen.js";
import AuthenticNarrationScreen from "./screens/AuthenticNarrationScreen.js";
import FaqScreen from "./screens/FaqScreen.js";
import BlogScreen from "./screens/BlogScreen.js";
import WhyChooseUsScreen from "./screens/WhyChooseUsScreen.js";
import OurClinicScreen from "./screens/OurClinicScreen.js";
import GdprScreen from "./screens/GdprScreen.js";
import LoginScreen from "./screens/auth/LoginScreen.js";
import RegisterScreen from "./screens/auth/RegisterScreen.js";
import ForgetPasswordScreen from "./screens/auth/ForgetPasswordScreen.js";
import DashboardScreen from "./screens/dash/DashboardScreen.js";
import CourseScreen from "./screens/cours/CourseScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/contact-us",
    element: <ContactScreen />,
  },
  {
    path: "/pricing",
    element: <PricingScreen />,
  },
  {
    path: "/syllabus",
    element: <SyllabusScreen />,
  },
  {
    path: "/entry-requirements",
    element: <EntryRequirementScreen />,
  },
  {
    path: "/how-to-apply",
    element: <HowToApplyScreen />,
  },
  {
    path: "/what-is-hijama",
    element: <WhatIsHijamaScreen />,
  },
  {
    path: "/who-should-perform-hijama",
    element: <WhoShouldPerformHijamaScreen />,
  },
  {
    path: "/our-story",
    element: <OurStoryScreen />,
  },
  {
    path: "/authentic-narrations",
    element: <AuthenticNarrationScreen />,
  },
  {
    path: "/faqs",
    element: <FaqScreen />,
  },
  {
    path: "/blogs",
    element: <BlogScreen />,
  },
  {
    path: "/why-choose-us",
    element: <WhyChooseUsScreen />,
  },
  {
    path: "/our-clinics",
    element: <OurClinicScreen />,
  },
  {
    path: "/gdpr",
    element: <GdprScreen />,
  },
  {
    path: "/login-account",
    element: <LoginScreen />,
  },
  {
    path: "/register-account",
    element: <RegisterScreen />,
  },
  {
    path: "/forget-password",
    element: <ForgetPasswordScreen />,
  },
  {
    path: "/dashboard",
    element: <DashboardScreen />,
  },
  {
    path: "/course-page/:id",
    element: <CourseScreen />,
  },

  //
  // {
  //   path: "/profile",
  //   element: <ProfileScreen />,
  // },
  // {
  //   path: "/logout",
  //   element: <LogoutScreen />,
  // },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
