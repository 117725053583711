import React, { useState } from "react";
import FooterWeb from "../../layouts/FooterWeb";
import HeaderWeb from "../../layouts/HeaderWeb";

function DashboardScreen() {
  const [selectPage, setSelectPage] = useState("Enrolled Courses");
  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              User Account
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto">
        <div className="my-10">
          <div className="flex flex-row justify-around bg-primary w-full pt-1 text-white text-xs">
            <div
              onClick={() => {
                setSelectPage("Enrolled Courses");
              }}
              className={`${
                selectPage === "Enrolled Courses" ? "bg-white text-primary" : ""
              } w-full flex-1 py-3 text-center cursor-pointer`}
            >
              Enrolled Courses
            </div>
            <div
              onClick={() => {
                setSelectPage("Setting");
              }}
              className={`${
                selectPage === "Setting" ? "bg-white text-primary" : ""
              } w-full flex-1 py-3 text-center cursor-pointer`}
            >
              Setting
            </div>
            <div
              onClick={() => {
                setSelectPage("Messages");
              }}
              className={`${
                selectPage === "Messages" ? "bg-white text-primary" : ""
              } w-full flex-1 py-3 text-center cursor-pointer`}
            >
              Messages
            </div>
            <div
              onClick={() => {
                setSelectPage("Wishlist");
              }}
              className={`${
                selectPage === "Wishlist" ? "bg-white text-primary" : ""
              } w-full flex-1 py-3 text-center cursor-pointer`}
            >
              Wishlist
            </div>
            <div
              onClick={() => {
                setSelectPage("Enrolled Quizzes");
              }}
              className={`${
                selectPage === "Enrolled Quizzes" ? "bg-white text-primary" : ""
              } w-full flex-1 py-3 text-center cursor-pointer`}
            >
              Enrolled Quizzes
            </div>
            <div
              onClick={() => {
                setSelectPage("Orders");
              }}
              className={`${
                selectPage === "Orders" ? "bg-white text-primary" : ""
              } w-full flex-1 py-3 text-center cursor-pointer`}
            >
              Orders
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default DashboardScreen;
